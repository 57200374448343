.navbar-section {
    position: relative;
    height: 90px;
    z-index: 10;
    top: 0;
    background-color: #fff;
    width: 100%;
    box-shadow: 1px 1px 3px rgb(65, 65, 65);

}

.navbar-container {
    width: 95%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
}

.navbar-container .logo-container {
    flex: 1.2;
    display: flex;
    align-items: center;
}

.navbar-container .logo-container img {
    width: 100%;
}

.navbar-container .side-nav {
    flex: 2;
    display: flex;
    padding-left: 30px;
}


.donate-button {
    text-align: center;
    margin: 0;
}

.donate-button span {
    background-color: #29a0d1;
    padding: 12px 30px;
    border-radius: 2px;
    color: white;
    transition: 0.5s;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.donate-button span:hover {
    background-color: #2090c0;
}

.navbar-container ul {
    flex: 3;
    display: flex;
    list-style: none;
    font-size: 18px;
    height: 30%;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 400;
    padding-left: 50px;
}



.navbar-container ul li {
    transition: 0.5s;
    text-transform: uppercase;
    font-size: 15px;
    color: #000;
    font-weight: 500;
}

.navbar-container ul a:hover{
    text-decoration: none;
}


.navbar-container ul li:hover {
    color: #29a0d1;
}


.underlineHover {
    position: relative;
  }
  
  .underlineHover:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0.1em;
    display: block;
    margin-top: 5px;
    right: 0;
    background: #29a0d1;
    transition: width .3s ease;
    -webkit-transition: width .3s ease;
  }
  
  .underlineHover:hover:after {
    width: 100%;
    max-height: 0.1em;
    left: 0;
    background: #29a0d1;
  }

  .vertical-line::after{
    content: "";
    border-right: 1px solid #dbdbdb;
    padding: 12px 0px;
    border-radius: 5px;    
  }


  .logo-container {
    display: flex;
  }
  .logo-container i {
    display: none;
  }
  

  .mobile-bar {
    display: none;
  }


  @media screen and (max-width: 1360px){
    .verified {
        display: none;
    }
  }
@media screen and (max-width: 1060px){
   .navbar-container {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0;
    left: 0;
    position: relative;
    opacity: 0;
    width: 0px;
    transition: 0.5s ease-in-out;
   }

   .navbar-container.active {
    opacity: 1;
    width: 100%;
   }

   .navbar-container ul {
    flex: 5;
    margin-left: 0;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
}

.navbar-container ul li { 
    padding: 15px 0px;
}

.navbar-section {
    background-color: #fff;
    height: 100%;
    position: fixed;
    padding-bottom: 50px;
    width: fit-content;
    
}

.logo-container {
    width: 100%;
    padding: 20px 20px;
}

.logo-container img {
    height: 80px;
    width: 100%;
}

.vertical-line {
    display: none;
}


.donate-button {
    position: relative;
    top: 25px;

}

.logo-container {
    justify-content: space-between;
}

.logo-container i {
    display: block;
    position: relative;
    top: -50px;
    right: 10px;
    font-size: 20px;
}

.mobile-bar {
    position: fixed;
    z-index: 10000;
    padding: 25px;
    display: block;
}

.mobile-bar i {
    color: #000;
    font-size: 20px;
}

.verified.top {
    padding-top: 50px;
}
.verified{
    display: block;    
    position: relative;
    left: -25px;
}

.verified img {
    width: 45px;
    height: 30px;
    position: relative;
    top: -1px;
}
}
.verified {
    height: 100%;
    margin: auto 0px;
    font-weight: 600;
}

.verified i {
    color: #29a0d1;
    font-weight: 600;
}

.verified img {
    width: 45px;
    height: 30px;
    position: relative;
    top: -1px;
    right: -10px;
}

/*

@media screen and (max-width: 1060px){ 
    .fonder-content-container {
        flex-direction: column;
    }

    .testimonial-box {
        width: 400px
    }

    .fonder-content-container.mid {
        flex-direction: column-reverse;
    }

    .founder-image-wrapper {
        width: 400px;
        padding: 50px 0px;
    }
    .founder-image-wrapper.right {
        padding-right: 0px;
    }
    
    .founder-image-wrapper.left {
        padding-left: 0px;
    }
    
    .fonder-content-container.left {
        padding-left: 0px;
    }
}



@media screen and (max-width: 650px){ 

    .founder-image-wrapper {
        width: 300px;
    }

    .home-title-card h1 {
        font-size: 60px;
    }

    .home-title-card h2 {
        font-size: 24px;
        top: -50px;
    }

    .course-tile-container h1 {
        font-size: 60px;
    }

    .course-tile-container h2 {
        font-size: 24px;
        top: -50px;
    }
    .home-title-card-founder h1 {
        font-size: 60px;
    }

    .home-title-card-founder h2 {
        font-size: 24px;
        top: -50px;
    }
    .partner-tile-container h1 {
        font-size: 60px;
    }

    .partner-tile-container h2 {
        font-size: 24px;
        top: -50px;
    }

    .testimonial-box::before {
        display: none;
    }

    .testimonial-box::after {
        display: none;
    }

    .testimonial-box { 
        width: 80vw;
    }
}

*/