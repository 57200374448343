.footer-section {
    width: 100%;
    height: 100%;
    padding: 50px 0px 25px 0px;
    background-color: #2090c0;
}

.footer-container {
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
}


.footer-nav-links a:hover{
    text-decoration: none;
}

.footer-section-1 {
    display: flex;
    align-items: center;
}

.footer-about-us-container {
    flex: 2;
}

.footer-logo-wrapper {
    width: 700px;
    height: 150px;
    margin-top: 25px;
}

.footer-logo-wrapper img {
    height: 100%;
    width: 100%;
}

.footer-contact-us {
    flex: 1;
}

.footer-about-us-content {
    width: 80%;
    padding-top: 25px;
    color: rgb(233, 233, 233);
}

.footer-read-more span{
    padding: 10px 25px;
    border: 2px solid rgb(216, 216, 216);
    cursor: pointer;
    color: rgb(233, 233, 233);
    font-size: 16px;
    letter-spacing: 0.1em;
    transition: 0.3s;
    font-weight: 500;
}

.footer-read-more {
    padding-top: 25px;
}

.footer-read-more span:hover {
    color: #4b4a4c;
    background-color: #fff;
    border: 2px solid #fff;
}

.footer-contact-us h2 {
    font-size: 18px;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    padding-bottom: 5px;
    position: relative;
}

.footer-contact-us h2::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    height: 3px;
    width: 55px;
    border-radius: 8px;
    background-color: #4b4a4c;
}


.address {
    padding-top: 5px;
    color: rgb(255, 255, 255)
}

.cont-details {
    color: rgb(255, 255, 255);
    font-weight: 600;

    display: flex;
}

.cont-details a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-weight: 400;
    padding-left: 10px;
    flex: 5;
}

.cont-details span {
    flex: 1;
}
.cont-details.act {
    margin-bottom: 0;
}

.social-nav-links {
    display: flex;
    justify-content: space-between;
    width: 50%;
    color: rgb(233, 233, 233);
    padding-top: 15px;
}
.social-nav-links a {
    color: rgb(233, 233, 233);
}
.social-nav-links a i {
    flex: 1;
    background-color: #4b4a4c;
    padding: 15px;
    margin-right: 10px;
    border-radius: 4px;
    transition: 0.3s;
}
.social-nav-links a i:hover{
    transform: translateY(-10px);
}


.footer-section-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copy-rights p {
    color: #4b4a4c
}

.copy-rights span {
    font-weight: 600;
}

.copy-rights span a {
    color: #4b4a4c;
    font-weight: 600;
}

.footer-nav-links ul {
    display: flex;
    list-style: none;
}
.footer-nav-links ul li {
    padding: 0px 15px;
    color: #4b4a4c;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
}

@media(max-width: 1330px) { 
    .footer-section-1 {
        justify-content: space-between;
    }
    .footer-container {
        width: 90%;
    }
    .footer-logo-wrapper {
        width: 500px;
        height: 110px;
        margin-top: 25px;
    }
    
}

@media(max-width: 880px) { 
    .footer-container {
        width: 95%;
    }

    .footer-section-1 {
        flex-direction: column;
    }
    .footer-about-us-container {
        width: 80%;
        margin: auto;

    }
    .footer-contact-us {
        width: 80%;
        padding-top: 50px;
    }
    .footer-section-2 {
        flex-direction: column;
    }
    .copy-rights {
        width: 90%;
        text-align: center;
    }
    .footer-nav-links ul li {
        text-align: center;
        width: 80%;
        margin: auto;
    }

    .footer-about-us-content {
        width: 100%;
    }

    .footer-logo-wrapper {
        width: 100%;
        height: 100%;
    }
}

  


@media(max-width: 550px) { 
    .footer-nav-links {
        display: none;
    }
}