

.error {
    background: linear-gradient(-45deg, rgba(255, 210, 53, 1), rgba(82, 113, 255, 1), rgba(255, 121, 38, 1), rgba(12, 146, 223, 1));
	background-size: 200% 200%;
    height: 100vh;
	animation: gradient 15s ease infinite;
}


@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.error h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-end;
    color: white;
    font-size: 100px;
    width: 100%;
    height: 50%;    
}   

.error p {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    color: white;
    font-size: 30px;
    width: 100%;
    height: 50%;    
}   