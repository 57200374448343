.project-section {
    width: 80%;
    margin: auto;
    padding-bottom: 50px;
    padding-top: 50px;
}
.project-img-wrapper {
    padding-top: 50px;
    padding-bottom: 20px;
}


.project-content h1 {
    margin: 0px;
    padding: 0px 0px 5px 0px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #4b4a4c;
    border-bottom: 1px solid #d8d8d8;
    width: fit-content;
}

.blog-title {
    display: flex;
    justify-content: center;
}

.blog-sub-title {
    display: flex;
    padding: 10px 0px;
    justify-content: center;
    color: #b4b4b4;
}



.blog-content-container {
    color: #777777;
}

.center {
    padding: 0px 10px;
}




.thumbnail.mt-3 {
    width: 100%;
    height: 700px;
}

.thumbnail img {
    padding-bottom: 50px;
    width: 100%;
    height: 100%;
    object-fit:contain;
}


.mt-3{
    margin-top: 50px;
}

