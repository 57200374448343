.social-navbar-conatiner {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    height: 30px;
    align-items: center;
}

.social-navbar-section {
    background-color: #f1f1f1;
    color: rgb(156, 156, 156);
}

.social-navbar-conatiner p {
    font-size: 13px;
    margin: 0;
}

.welcome-container {
    display: flex;
    height: 100%;
    align-items: center;
}

.welcome-container a {
    padding-left: 10px;
    text-decoration: none;
    color: rgb(156, 156, 156);
    font-size: 13px;
}

.follow-us-container {
    display: flex;
    height: 100%;
    align-items: center;
}

.follow-us-container a {
    color: rgb(156, 156, 156);
    text-decoration: none;
}
.follow-us-container p {
    padding-right: 10px;
    margin: 0;
}
.follow-us-container i {
    padding-right: 10px;
}

.nav-contact {
    display: flex;
    height: 100%;
    align-items: center;
    font-size: 13px;
}

.nav-mobile {
    display: flex;
    height: 100%;
    align-items: center;
    padding-right: 25px;
}
.nav-email {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 25px;
}

.nav-contact i {
    padding-right: 10px;
}

.nav-contact a {
    height: 100%;
    align-items: center;
    text-decoration: none;
    color: rgb(156, 156, 156);
    margin: 0;
}


@media screen and (max-width: 1060px){
    .social-navbar-section {
        display: none;
    }
}