.coaching-wrapper {
    height: 90vh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)), url("./../../Asserts/coaching/classroom.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}


.coaching-container {
    display: flex;
    justify-content: space-between;
}

.doc-img-wrapper {
    padding-top: 50px;
    flex: 1;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coaching-details {
    flex: 1;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px 15px;
}

.coaching-details h1 {
    font-size: 65px;
    color: #29a0d1;
    padding-bottom: 10px;
}

.coaching-details h2 {
    font-size: 32px;
    color: #02618a;
    padding-bottom: 20px;
    padding-right: 50px;
}

.coaching-details h4 {
    font-size: 18px;
    color: #5e5e5e;
    font-weight: 500;
}


.join-us-container {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px;
}

.join-us-wrapper {
    background-color: #2090c0;
    height: 100%;
    display: flex;
    border-radius: 5px;
    padding: 50px;
    align-items: center;
    justify-content: center;
}

.join-now {
    position: relative;
    top: -3px;
}
.join-us-section {
    background-color: #f2f1f1;
}

.join-us-content-card {
    flex: 2;
    padding-right: 10px;
}

.join-us-content-card a {
    color: #3b3b3b;
    font-weight: 700;
    text-decoration: none;
    transition: 0.3s;
}

.join-us-content-card a:hover {
    border-bottom: 1px solid #474747;
    color: #474747;
}


.join-us-img-wrapper {
    flex: 1;
}

.join-us-content-card  {
    color: white;
}

.join-cmt {
    color: #51cafe;
}

.join-us-content-card h1 {
    font-size: 50px;
    margin-bottom: 15px;
}

.join-us-content-card p {
    font-size: 18px;
    font-weight: 300;
    width: 90%;
}

.join-us-wrapper.message-box {
    width: 80%;
    margin: auto;
    position: relative;
    top: -45px;
}

.what-vdo-wrapper {
    height: 100%;
    background-image: linear-gradient(rgba(41, 139, 182, 0.8), rgb(153, 211, 237)), url("./../../Asserts/services/student.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}




.what-vdo-container {
    width: 97%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0px;
}

.service-content-container {
    display: flex;
    width: 80%;
    position: relative;
    left: 18px;
    padding: 10px 0px;
    flex: 1;
    align-items: center;
    height: 100%;
}

.service-content-container h4 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.service-content-container i {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service-content-container h4 {
    font-size: 18px;
    color: rgb(255, 255, 255);
    padding-left: 15px;
}

.service-content-container i {
    font-size: 30px;
    color: white;
}

.service-content {
    display: flex;
    flex-direction: column;
}

.service-img-wrapper {
    width: 40vw;
}

.about-us-container-neet {
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 0px;
}

.about-us-intro-card p {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    text-align: justify;
    font-size: 18px;
    font-weight: 300;
}



.about-us-container h6,
.about-us-container li {
    text-align: justify;
}

.about-us-title {
    font-size: 70px;
    font-weight: 700;
    color: rgb(218, 218, 218);
    opacity: 0.4;
}

.about-us-container {
    padding-bottom: 100px;
}

.about-us-intro-card h6 {
    position: relative;
    top: -45px;
    left: 16px;
    color: #2090c0;
    font-weight: 600;
    margin-bottom: 0;
}

.abt-us-sub-title {
    font-size: 50px;
    width: 70%;
    margin-right: auto;
    margin-top: 0;
    position: relative;
    top: -35px;
    color: #2090c0;
    font-weight: 800;
}

.abt-us-content {
    width: 85%;
    color: #4b4a4c;
    font-weight: 300;
    position: relative;
    top: -20px;
}

.abt-us-content b {
    font-weight: 700
}

.about-us-intro-card p a {
    font-size: 13px;
    color: #2090c0;
    font-weight: 600;
    position: relative;
    top: -15px;
}

.about-us-intro-card p a span {
    padding-left: 5px;

}

.about-us-vm-card h1 {
    font-size: 23px;
    font-weight: 800;
    color: #2090c0;
}

.about-us-vm-card ul {
    width: 75%;
    position: relative;
    left: 20px;
    top: 10px;
    color: #4b4a4c;
    font-size: 17px;
    font-weight: 300;
}

.about-us-vm-card ul li {
    padding: 5px 0px;
}



.course-tile-container.neet h2 {
    font-size: 28px;
}

.course-tile-container.neet h1 {
    font-size: 85px;
}

.course-tile-container.neet.wvd h1 {
    color: #12749e;
}

.course-tile-container.neet.wvd h2 {
    color: white;
}


.wvd-subtitle {
    position: relative;
    top: -20px;
    left: 15px;
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}

@media screen and (max-width: 880px){ 
    .doc-img-wrapper {
        display: none;
    }
    .coaching-details {
        padding: 25px 50px;
    }

    .join-us-wrapper.message-box {
        width: 80%;
        margin: auto;
        position: relative;
        top: -150px;
    }

    .what-vdo-container {
        flex-direction: column;
        padding: 50px 0px;
    }


}


@media screen and (max-width: 650px){ 
    .coaching-details h1 {
        font-size: 35px;
        color: #29a0d1;
        padding-bottom: 10px;
    }
    
    .coaching-details h2 {
        font-size: 25px;
        color: #02618a;
        padding-bottom: 20px;
        padding-right: 50px;
    }
    
    .coaching-details h4 {
        font-size: 12px;
        color: #5e5e5e;
        font-weight: 500;
    }

    .coaching-wrapper {
        height: 100%;
        background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.8)), url("./../../Asserts/coaching/classroom.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .join-us-wrapper.message-box {
        width: 80%;
        margin: auto;
        position: relative;
        top: 10px;
    }

    .join-us-content-card h1 {
        font-size: 25px;
        margin-bottom: 15px;
    }
    
    .join-us-content-card p {
        font-size: 15px;
        font-weight: 300;
        width: 90%;
    }

    .join-us-content-card a {
        font-size: 12px;
    }

    .course-tile-container.neet h1 {
        font-size: 60px;
    }

    .course-tile-container.neet h2 {
        font-size: 25px;
    }

    .wvd-subtitle {
        font-size: 30px;
        max-width: 70vw;
    }

    .service-content h4 {
        font-size: 15px;
    }
    
    
}

@media screen and (max-width: 550px){
    .join-us-img-wrapper {
        display: none;
    }
}