.img-gallery {
    width: 90%;
    margin: 50px auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 50px;
}
.gal-img-wrapper {
    height: 350px;
    width: 350px;
}


.gallery-img-container {
    top: 0;
    min-height: 60vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.4), rgba(4, 9, 30, 0.8)), url("../../Asserts/gallery.jpg");
    background-position: 100%;
    background-size: cover;
    position: relative;
}

.gallery-img-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.gallery-img-container h1 {
    font-size: 100px;
    opacity: 0.85;
}


.img-gallery img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: 0.3s;
    object-fit: cover;
}

.img-gallery img:hover {
    transform: scale(1.05) rotate(4deg);
    border-radius: 10px;
    box-shadow: 0 32px 75px rgba(68,77,136,0.2);
}

.full-img {
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

#fullImgBox {
    display: flex;

}
.full-img img {
    max-width: 600px;
    max-height: 100vw;
}

.full-img span {
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 30px;
    color: #fff;
    cursor: pointer;
    z-index: 500;
}