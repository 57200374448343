.contactMainContainer h1 {
    margin: 50px 0px;
  }
  
  .contactInformation {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    align-items: center;
    padding: 30px;
  }
  
  .contactMainTable {
    height: 30vh;
  }
  
  .contactMainTable td i {
    color: #4b4a4c;
  }
  
  .contactMainTable .contactTableHeading {
    padding: 0px 80px 0px 0px;
  }
  
  .contactMainTable .contactTableSubHeading {
    padding: 0px 40px 0px 0px;
  }
  
  .contactSubTable td h5 {
    margin: 5px 0px;
  }
  
  .contactSubTable td p a {
    line-height: 25px;
  }
  
  .mainAddress h4 {
    margin: 7px 0px;
  }
  
  .getInTouchSection {
    margin-top: 40px;
  }
  
  .contactSubTable td p {
    color: #6f6f6f;
    margin: 0;
    padding-bottom: 15px;
    font-size: 15px;
  }
  
  .contactSubTable td h5 {
    color: #4f4f4f;
  }
  
  .contact-input-pair {
    display: flex;
    justify-content: space-between;
  }
  
  .floating-label-group-space {
    margin: 0px 50px;
  }
  .floating-label-group p {
    color: rgb(189, 2, 2);
    font-size: 13px;
    font-weight: 500;
    padding-top: 0;
    margin-top: 0;
    position: relative;
    top: -15px;
    font-style: italic;
  }

  .special-p {
    color: rgb(189, 2, 2);
    font-size: 13px;
    font-weight: 500;
    padding-top: 0;
    margin-top: 0;
    position: relative;
    top: -55px;
    font-style: italic;
  }
  .contact-input-pair .floating-label-group {
    width: 100%;
  }
  
  .getInTouchSectionContainer input,
  .getInTouchSectionContainer textarea {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(154, 154, 154);
    text-indent: 2px;
    color: #4b4a4c;
    width: 100%;
    font-size: 16px;
  }
  
  .getInTouchSectionContainer input:hover {
    border-bottom: 2px solid #29a0d1;
  }
  
  .getInTouchSectionContainer input:focus {
    outline: none;
    border-bottom: 2px solid #29a0d1;
  }
  
  .getInTouchSectionContainer textarea:focus {
    outline: none;
    border-bottom: 2px solid #29a0d1;
  }
  
  .getInTouchSectionContainer textarea {
    height: 200px;
    margin-bottom: 50px;
  }
  
  
  .getInTouchSectionContainer .floating-label-group .floating-label {
    font-size: 16px;
  }
  
  .getInTouchSectionContainer .floating-label-group input:focus~.floating-label,
  .getInTouchSectionContainer.floating-label-group input:not(:focus):valid~.floating-label {
    font-size: 14px;
  }
  
  .getInTouchSectionContainer .floating-label-group input {
    padding: 7px 0px;
  }
  
  .getInTouchSectionContainer h1 {
    margin-top: 15px;
  }
  .floating-label-group-button button {
    width: 100%;
    padding: 18px 30px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 150px;
    transition: 0.5s;
    border: none;
    color: white;
    background-color: #29a0d1;
  }
  
  .floating-label-group-button button:hover {
    background-color: #0c88bd;
    color: white;
  }
  
  .india-Office p {
    margin: 0;
    font-size: 13px;
    line-height: 1.3em;
    font-style: italic;
  }
  
  
  @media(max-width: 550px) {
    .entry-titles {
      font-size: 40px;
    }
  
    .entry-subtitles p {
      font-size: 20px;
      line-height: normal;
      letter-spacing: normal;
    }
  
    .page-titles {
      width: 100%;
    }
  
    .contactMainContainer h1 {
      font-size: 26px;
    }
  
    .contactMainContainer table h4 {
      font-size: 14px;
    }
  
    .contactMainTable .contactTableHeading {
      padding: 0px 25px 0px 0px;
    }
  
    .contactMainTable .contactTableSubHeading {
      padding: 0px 15px 0px 0px;
    }
  
    .floating-label-group-space {
      margin: 0px 15px;
    }
  
    .contactMainContainer {
      padding: 15px;
    }

    .contact-input-pair {
      flex-direction: column
    }

    .contactMainContainer {
      max-width: 90vw;
      margin: auto;
    }

    .contactSubTable h5 {
      font-size: 12px;
    }

    .contactSubTable p a {
      font-size: 12px;
    }

    .india-Office {
      display: none;
    }

    .getInTouchBody {
      position: relative;
      z-index: 100000;
    }
  
  
  }
  

  

.floating-label-group {
    position: relative;
  }
  
  .floating-label-group .floating-label {
    font-size: 13px;
    color: #2b2b2b;
    font-weight: 500;
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 0px;
    transition: all 0.1s ease;
    font-size: 14px;
  
  }
  
  .floating-label-group input:focus~.floating-label,
  .floating-label-group input:not(:focus):valid~.floating-label {
    top: -15px;
    bottom: 0px;
    left: 0px;
    opacity: 1;
    padding-top: 15px;
    color: #4b4a4c;
    font-size: 12px;
  }
  
  .floating-label-group input {
    margin: 20px 0px;
    font-size: 16px;
    padding: 5px 0px;
  }
  
  .floating-label-group input:focus {
    outline: none;
  }
  
  .rememberMe {
    display: flex;
    margin: 10px 0px;
    justify-content: left;
  }
  
  .rememberMe input {
    flex: 0;
  }
  
  .rememberMe label {
    flex: 1;
    padding-left: 10px;
  }

  /*Message to Top*/

.textAreaContactSection {
    display: flex;
    flex-flow: column-reverse;
    position: relative;
    margin: 10px 0px;
  }
  
  .textAreaContactSection label,
  textarea {
    transition: all 0.2s;
    touch-action: manipulation;
    transform-origin: left bottom;
    font-size: 13px;
  }
  
  .textAreaContactSection textarea {
  
    font-family: inherit;
    -webkit-appearance: none;
    cursor: text;
    color: #4b4a4c;
    font-size: 16px;
  }
  
  .textAreaContactSection label {
    font-size: 11px;
  }
  
  .textAreaContactSection textarea:placeholder-shown+label {
    cursor: text;
    max-width: 66.66%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform: translate(0, 2.125rem) scale(1.5);
  }
  
  .textAreaContactSection ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }
  
  .textAreaContactSection textarea:not(:placeholder-shown)+label,
  .textAreaContactSection textarea:focus+label {
    transform: translate(0, 0) scale(1);
    cursor: pointer;
    font-size: 14px;
    color: #4b4a4c;
  
    padding-bottom: 10px;
  }

  .form-control.subject {
    height: 60px;
  }
  


.contactInformation a {
    text-decoration: none;
    color: #4b4a4c;
}


.contactInformation a:hover{
    text-decoration: none;
    color: #4b4a4c;
}

.contactInformation input:focus {
    outline: none;
}