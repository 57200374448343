.about-us-img-container {
    display: flex;
    justify-content: space-between;
}



.about-us-img-container {
    top: 0;
    min-height: 80vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.2), rgba(4, 9, 30, 0.7)), url("../../Asserts/about-us-new.jpg");
    background-position: top;
    background-size: cover;
    position: relative;
}

.about-us-img-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.about-us-img-container h1 {
    font-size: 100px;
    opacity: 0.85;
}



.about-us-content {
    padding-top: 50px;
    color: #4b4a4c;
    width: 70%;
    margin: auto;
}

.about-us-container-main {
    padding-bottom: 50px;
}


@media screen and (max-width: 1060px) {
    .about-us-img-container {
        min-height: 50vh;
    }
}

@media screen and (max-width: 650px) {
    
.about-us-img-container {
    top: 0;
    min-height: 30vh;
    width: 100%;
    background-image:none;
    background-position: top;
    background-size: cover;
    position: relative;
}

.about-us-img-container h1 {
    color: #4b4a4c;
    font-size: 60px;
}

.about-us-content {
    padding-top: 0;
}
}