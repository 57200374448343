.carousel-item {
    margin: 0;
    height: calc(100vh - 130px);
}


.carousel-item img {
    height: 100%;
}

.home-title-card {
    padding: 50px 15px 20px 15px;
    width: 100%;
    margin: auto;
}

.home-title-card h1 {
    text-align: center;
    color: #29a0d1;
    text-transform: uppercase;
    font-size: 100px;
    margin-top: 0;
    color: #b9b9b9;
    font-weight: 800;
    opacity: 0.3;
}

.home-title-card h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 40px;
    margin-top: 0;
    color: #2090c0;
    font-weight: 800;
    position: relative;
    top: -75px;
}

.home-title-card p {
    width: 80%;
    padding-top: 0px;
    margin: auto;
    text-align: justify;
    color: #4b4a4c;
}

.thisai-btn {
    text-align: center;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thisai-btn span {
    background-color: #29a0d1;
    padding: 10px 30px;
    border-radius: 2px;
    color: white;
    transition: 0.5s;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1em;
}

.thisai-btn span:hover {
    background-color: #11749e;
}


/************************************************************************************/

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    margin: 4rem 0vw;
    padding: 0;
    list-style-type: none;
}

.card {
    position: relative;
    display: block;
    height: 100%;
    border-radius: calc(var(--curve) * 1px);
    overflow: hidden;
    text-decoration: none;
}

.card__image {
    width: 100%;
    height: 350px;
    object-fit: cover;
}

.card__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(--curve) * 1px);
    background-color: #f3f5f7;
    transform: translateY(100%);
    transition: .2s ease-in-out;
    color: #000000;
}

.card:hover .card__overlay {
    transform: translateY(0);
}

.card__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2em;
    padding: 2em;
    border-radius: calc(var(--curve) * 1px) 0 0 0;
    background-color: #f3f5f7;
    transform: translateY(-100%);
    transition: .2s ease-in-out;
}

.card__arc {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.card__arc path {
    fill: var(--surface-color);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
    transform: translateY(0);
}

.card__thumb {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.card__title {
    font-size: 1em;
    margin: 0 0 .3em;
    color: #6A515E;
}

.card__tagline {
    display: block;
    margin: 1em 0;
    font-family: "MockFlowFont";
    font-size: .8em;
    color: #D7BDCA;
}

.card__status {
    font-size: .8em;
    color: #D7BDCA;
}

.card__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #9b9b9b;
    font-family: "MockFlowFont";
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

:root {
    --surface-color: #fff;
    --curve: 40;
}



.course-container {
    width: 80%;
    margin: auto;
    padding: 50px 0px;
}


.course-tile-container h1 {
    font-size: 90px;
    font-weight: 700;
    color: #b9b9b9;
    opacity: 0.3;
}



.course-tile-container h2 {
    font-size: 30px;
    width: 70%;
    margin-right: auto;
    margin-top: 0;
    position: relative;
    top: -62px;
    left: 20px;
    color: #2090c0;
    font-weight: 800;
    text-transform: uppercase;
}

.course-card {
    width: 100%;
}

/************************************************************************************/

.partner-container {
    width: 80%;
    margin: auto;
    padding: 50px 0px;
    text-align: right;
}

.partner-tile-container h1 {
    font-size: 90px;
    font-weight: 700;
    color: #b9b9b9;
    opacity: 0.3;
}



.partner-tile-container h2 {
    font-size: 30px;
    margin-top: 0;
    position: relative;
    top: -62px;
    right: 20px;
    color: #2090c0;
    font-weight: 800;
    text-transform: uppercase;
}


.partner-logo img {
    height: 100%;
    width: 100%;
}

.partner-logo-container {
    padding-bottom: 100px;
}

.partner-logo {
    margin: 0px 15px;
}


.read-more-btn {
    padding-top: 30px;
}

.read-more-btn a:hover {
    text-decoration: none;
    border: none;
}


.thisai-btn:hover {
    text-decoration: none;
}


/***************************************Founders Note************************************/


.home-title-card-founder {
    padding: 50px 15px 0px 15px;
    width: 100%;
    margin: auto;
}

.home-title-card-founder h1 {
    text-align: center;
    color: #29a0d1;
    font-size: 90px;
    margin-top: 0;
    color: #b9b9b9;
    font-weight: 800;
    opacity: 0.3;
}

.home-title-card-founder h2 {
    text-align: center;
    text-transform: uppercase;
    font-size: 30px;
    margin-top: 0;
    color: #2090c0;
    font-weight: 800;
    position: relative;
    top: -62px;
}


.founder-message {
    cursor: pointer;
    width: 360px;
    height: 360px;
    border-radius: 15px;
    margin: 15px;
    padding: 20px 25px;
    position: relative;
    top: -20px;
    background: rgba(41, 160, 209, 0.1);
    box-shadow: 10px 20px 25px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
}


.founder-message p {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #4b4a4c;
}

.founder-message h2 {
    font-weight: 500;
    margin-bottom: 10px;
}

.founder-message h4 {
    color: #585858;
    font-weight: 16px;
    font-size: 16px;
    line-height: 1.5em;
}

.founder-message i {
    font-size: 60px;
    position: absolute;
    bottom: 20px;
    right: 25px;
    color: #b2bec3;

}

.founder-message:hover {
    transform: scale(1.01);
    color: #333;
    background: rgba(41, 160, 209, 0.2);
    z-index: 10000;
}

.founder-img-wrapper-left {
    width: 360px;
    height: 340px;
    margin: auto;
    flex: 1;
}

.empty-founder {
    flex: 0.4;
}

.founder-img-wrapper-left img {
    width: 100%;
    height: 100%;
}



/****************************Founders note*************************************/
.container {
    width: 100%;
    height: 80%;
    display: grid;
    justify-content: center;
    align-content: center;
}

.testimonial-box {
    position: relative;
    width: 500px;
    height: 100%;
}

.testimonial-box::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -100px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%), rgba(255, 255, 255, 0.8);
    box-shadow: -5px 5px 10px rgba(0, 0, 0, .3);
    z-index: -1;
}

.testimonial-box::after {
    content: '';
    position: absolute;
    bottom: 10px;
    right: -100px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%), rgba(255, 255, 255, 0.8);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);
    z-index: -1;
}

.testimonial i {
    font-size: 32px;
    text-align: right;
    color: #3d3d3d;
    opacity: 0.5;
    height: fit-content;
}
.testimonial {
    display: grid;
    grid-template-rows: 0fr 0.7fr;
    box-shadow: 0 0 5px rgba(0, 0, 0, .5);
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
    padding: 10px 20px;
    position: relative;
}

.testimonial.fas {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    font-size: 25px;
    color: #222;
}

.testimonial-text {
    font-size: 16px;
    line-height: 22px;
    padding: 10px 15px;
    width: 100%;
    text-align: justify;
    font-weight: 400;
    color: #4b4a4c;

}

.testimonial h1 {
    font-size: 20px;
    padding: 0px 15px;
    text-align: right;
    color: #3b3b3b;
}

.testimonial-user {
    display: flex;
    align-items: center;
}

.testimonial-user.user-img {
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.user-info {
    margin: 0 20px;
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.user-info.user-name {
    font-weight: 800;
}

.user-job-details {
    margin: 5px 0 0 0;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.user-job-details.line {
    position: absolute;
    width: 2px;
    height: 100%;
    background: #000;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}



.support {
    position: absolute;
    right: 10px;
    bottom: 10px;
    padding: 10px;
    display: flex;
}


.fonder-content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100vw;
    margin: 30px 10px;
    padding-top: 15px;
}

.founder-image-wrapper {
    flex: 0.4;
    height: 350px;
}

.founder-image-wrapper img {
    box-shadow: 0 32px 75px rgba(68,77,136,0.2);
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    object-fit: cover;
    --s: 15px; /* control the size */
  padding: var(--s);
  border: var(--s) solid #0000;
  background: conic-gradient(from 90deg at 1px 1px,#0000 25%,#000 0);
  clip-path: 
    polygon(
      0    0   ,33%  0   ,50% calc(2*var(--s))       ,66%  0,
      100% 0   ,100% 33% ,calc(100% - 2*var(--s)) 50%,100% 66%,
      100% 100%,66%  100%,50% calc(100% - 2*var(--s)),33%  100%,
      0    100%,0    66% ,calc(2*var(--s)) 50%       ,0    33%
    )
}


  
  .founder-image-wrapper img {
    transition: all 0.3s;
  }
  
  .founder-image-wrapper:hover img {
    transform: scale(1.2) rotate(5deg);
  }

.container {
    flex: 1;
}

.join-us-section-home .join-us-container  {
    padding-top: 0;
}

.partner-logo-container {
    padding-bottom: 60px;
}

.founder-image-wrapper.right {
    padding-right: 50px;
}

.founder-image-wrapper.left {
    padding-left: 50px;
}

.fonder-content-container.left {
    padding-left: 50px;
}

@media screen and (max-width: 1060px){ 
    .fonder-content-container {
        flex-direction: column;
    }

    .testimonial-box {
        width: 400px
    }

    .fonder-content-container.mid {
        flex-direction: column-reverse;
    }

    .founder-image-wrapper {
        width: 400px;
        padding: 50px 0px;
    }
    .founder-image-wrapper.right {
        padding-right: 0px;
    }
    
    .founder-image-wrapper.left {
        padding-left: 0px;
    }
    
    .fonder-content-container.left {
        padding-left: 0px;
    }
}



@media screen and (max-width: 650px){ 

    .founder-image-wrapper {
        width: 300px;
    }

    .home-title-card h1 {
        font-size: 60px;
    }

    .home-title-card h2 {
        font-size: 24px;
        top: -50px;
    }

    .course-tile-container h1 {
        font-size: 60px;
    }

    .course-tile-container h2 {
        font-size: 24px;
        top: -50px;
    }
    .home-title-card-founder h1 {
        font-size: 60px;
    }

    .home-title-card-founder h2 {
        font-size: 24px;
        top: -50px;
    }
    .partner-tile-container h1 {
        font-size: 60px;
    }

    .partner-tile-container h2 {
        font-size: 24px;
        top: -50px;
    }

    .testimonial-box::before {
        display: none;
    }

    .testimonial-box::after {
        display: none;
    }

    .testimonial-box { 
        width: 80vw;
    }
}