@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Poppins&family=Roboto&display=swap');

/* root styling */


.project-img-container {
    top: 0;
    min-height: 60vh;
    width: 100%;
    background-image: linear-gradient(rgba(4, 9, 30, 0.2), rgba(4, 9, 30, 0.7)), url("../../Asserts/hands.jpg");
    background-position: 100%;
    background-size: cover;
    position: relative;
}

.project-img-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.project-img-container h1 {
    font-size: 100px;
    opacity: 0.85;
}


:root{
    --light : #f8f9fa;
    --secondary: #adb5bd;
    --dark: #343a40;
    --primary-color: #f15bb5;
    --secondary-color: #2ec4b6;
    --border : #e9ecef;
}

* > *{
    box-sizing: border-box;
}


/* global styling */
.text-light{
    color: var(--light);
}

.text-secondary{
    color: var(--secondary);
}

.text-dark{
    color: var(--dark);
}

.text-primary{
    color: var(--primary-color);
}

.bg-light{
    background-color: var(--light);
}

.blog-container{
    max-width: 90%;
    padding: 0 15px;
    margin: auto;
}

.img-fluid{
    width: 100%;
}

.text-title{
    font-weight: bold;
}

.secondary-title{
    padding-top: 10px;
}

.center {
    padding: 0px 15px;
}

.display-1{
    font-size: 22px;
}

.display-2{
    font-size: 16px;
}

.display-3{
    font-size: 14px;
}

.text-center{
    text-align: center;
}

.text-right{
    text-align: right;
}

.btn{
    padding: 15px 20px;
    border: none;
}

.btn-primary{
    border-radius: 4px;
    background-color: var(--secondary-color);
}

.object-fit{
    max-height: 120px;
    height: 80px;
    width: 80px;
    object-fit: fill;
}

.d-flex{
    display: flex;   
}

.flex-wrap{
    flex-wrap: wrap;
}

.justify-content-center{
    justify-content: center;
}

.justify-content-between{
    justify-content: space-between;
}

.mt-2{
    margin-top: 10px;
}

.mt-3{
    margin-top: 50px;
}

.mb-3{
    margin-bottom: 30px;
}

.m-0{
    margin: 0;
}

.px-1{
    padding-left: 5px;
    padding-right: 5px;
}

.px-2{
    padding-left: 20px;
    padding-right: 20px;
}

.py-1{
    padding-top: 10px;
    padding-bottom: 10px;
}

.py-2{
    padding-top: 20px;
    padding-bottom: 20px;
}

.py-3{
    padding-top: 30px;
    padding-bottom: 30px;
}

.thumbnail{
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.rounded{
    height: 120px;
    width: 120px;
    object-fit: fill;
    border-radius: 99px;
}

.shadow{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}


/* section styling */
 


/* ----------- Main Section ---------- */

#site-main{
    margin-top: 50px;
}

#posts{
    margin-bottom: 15em;
}

.grid{
    margin: 0 auto;
}

.grid .grid-item{
    width: calc(33.3333% - 20px);
    margin-bottom: 1em;
}

/* ----------- .Main Section ---------- */

/* ----------- footer ---------- */
#footer{
    padding-top: 4em;
}

#footer .feature-post .flex-item{
    margin-bottom: 10px;
}

#footer .tags a{
    border-radius: 40px;
    padding: 10px 20px;
    font-size: 15px;
}

#footer .copyrights{
    padding: 10px 0;
    border-top: 2px solid var(--light);
}


/* ----------- .footer ---------- */

/* ----------- article page ------- */

#post{
    padding: 0 2em;
    margin-bottom: 4em;
}

.post-footer .post-author{
    position: relative;
    margin-top: 4em;
    background-color: var(--light);
}

.post-footer .post-author::after{
    background-color: white;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4em;
    z-index: 0;
}

.post-footer .post-author .author-avatar{
    margin: 0 auto;
    margin-bottom: 1em;
    position: inherit;
    z-index: 1;
}

.post-footer .post-comments .reply{
    padding-left: 7em;
}

.form-control{
    width: 100%;
    border: 2px solid var(--border);
    padding: 1em;
    font-size: 18px;
    margin-right: 10px;
}

/* ----------- .article page ------- */

/* ----------- sidebar page ------- */
#sidebar .categories a{
    padding: 1em 3em;
    font-size: 18px;
    margin-bottom: 1em;
    background-color: var(--light);
    border-radius: 99px;
}

#sidebar .categories a:hover{
    background-color: var(--primary-color);
    color: var(--light);
}

/* ----------- .sidebar page ------- */

/* ----------- sticky ------- */

.sticky{
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
}

.sticky + .content{
    padding-top: 60px;
}

/* ----------- .sticky ------- */



/* Media Query */

.row{
    display: flex;
}

.col-3{
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    padding-right: 35px;
}

.col-8{
    flex: 0 0 70%;
    max-width: 70%;
}

.col-4{
    flex: 0 0 30%;
    max-width: 30%;
}

@media (max-width : 1024px){
    .row{
       flex-wrap: wrap;
    }

    .col-3{
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-8{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .col-4{
        flex: 0 0 100%;
        max-width: 100%;
    }

}


@media (max-width : 992px){
    .navbar{
        flex-direction: column;
    }

    #site-main{
        margin-top: 14em;
    }
}

@media (max-width : 768px){
    .grid .grid-item{
        width: calc(50% - 20px);
    }

    .col-3{
        flex: 0 0 100%;
        max-width: calc(100% - 50px);
        padding-top: 40px 50px;
    }
}

@media (max-width : 574px){
    .toggle-button{
        display: initial;
    }

    .collapse{
        max-height: 0;
        overflow: hidden;
        transition: all 0.8s cubic-bezier(0.51,-0.15, 0, 0.98);
    }

    .collapse .nav-link{
        display: block;
        text-align: center;
    }

    .search-box{
        border-right: none;
    }

    .collapse-toggle{
        max-height: 500px;
    }

    .grid .grid-item{
        width: calc(100% - 20px);
    }

    #site-main{
        margin-top: 6em;
    }

}